import { brandLogoSchema } from '#db/schema/brandSchema.js'
import { brandVoiceSchema } from '#db/schema/brandVoiceSchema.js'
import { featureSchema } from '#db/schema/featureSchema.ts'
import {
	personaToUserFlow,
	tagToFeature,
	tagToProject,
} from '#db/schema/generatedRelations.ts'
import { inspirationWebsiteSchema } from '#db/schema/inspirationWebsiteSchema.ts'
import { marketingSchema } from '#db/schema/marketingSchema.ts'
import { modelSchema } from '#db/schema/modelSchema.js'
import { pageSchema } from '#db/schema/pageSchema.js'
import { painSchema } from '#db/schema/painSchema.ts'
import { personaSchema } from '#db/schema/personaSchema.ts'
import { projectSchema } from '#db/schema/projectSchema.ts'
import { roleSchema } from '#db/schema/roleSchema.ts'
import { tagSchema } from '#db/schema/tagSchema.ts'
import { stepSchema, userFlowSchema } from '#db/schema/userFlowSchema.ts'
import { z } from 'zod'
import { omit } from './lodash.ts'
import { type modelNames } from './modelNames.ts'
import {
	genProps,
	hasOne,
	idList,
	manyToMany,
	schemas,
	type Model,
} from './modelUtils.ts'

export const models = {
	brand: {
		...genProps('brand', { inProject: true, namePlural: 'brand' }),
		drizzleSchema: brandLogoSchema,
		helpText: '',
		icon: 'palette',
		inProject: true,
		relations: {
			...hasOne('project'),
			// ...manyToMany('material', materialToPersona),
			// ...manyToMany('tag', tagToCustomer),
		},
		schema: z.object({
			...schemas,
			primaryColor: z.string(),
			tagIds: idList,
		}),
		starable: true,
	},
	brandVoice: {
		...genProps('brandVoice', { inProject: true }),
		drizzleSchema: brandVoiceSchema,
		helpText: '',
		icon: 'square-user-round',
		inProject: true,
		relations: {
			...hasOne('project'),
			// ...manyToMany('material', materialToPersona),
			// ...manyToMany('tag', tagToCustomer),
		},
		schema: z.object({
			...schemas,
		}),
		starable: true,
	},
	feature: {
		...genProps('feature', { inProject: true }),
		drizzleSchema: featureSchema,
		helpText:
			'Features are the functionality that your customers use in your product',
		icon: 'box',
		inProject: true,
		relations: {
			// ...manyToMany('event', eventToFeature),
			// ...manyToMany('material', materialToFeature),
			// ...manyToMany('tag', tagToFeature),
			// ...hasOne('project'),
		},
		schema: z.object({
			...schemas,
			tagIds: idList,
		}),
		starable: true,
	},
	inspirationWebsite: {
		...genProps('inspirationWebsite', { inProject: true }),
		drizzleSchema: inspirationWebsiteSchema,
		helpText: 'Websites that inspire you',
		icon: 'square-kanban',
		inProject: true,
		relations: {
			...hasOne('project'),
		},
		schema: z.object({
			...schemas,
			personaId: z.string(),
			tagIds: idList,
		}),
	},
	marketing: {
		...genProps('marketing', { inProject: true, namePlural: 'marketing' }),
		drizzleSchema: marketingSchema,
		helpText: 'Marketing is the process of promoting your product to customers',
		icon: 'message-circle-heart',
		inProject: true,
		relations: {
			// ...manyToMany('event', eventToFeature),
			// ...manyToMany('material', materialToFeature),
			// ...manyToMany('tag', tagToFeature),
			// ...hasOne('project'),
		},
		schema: z.object({
			...schemas,
			heroDescription: z.string().optional(),
			heroTitle: z.string().optional(),
			tagIds: idList,
		}),
	},
	model: {
		...genProps('model', { inProject: true }),
		drizzleSchema: modelSchema,
		helpText: 'Models are the data tables for your project',
		icon: 'database',
		inProject: true,
		relations: {
			// ...manyToMany('material', materialToPersona),
			// ...manyToMany('tag', tagToCustomer),
		},
		schema: z.object({
			...schemas,
			tagIds: idList,
		}),
		starable: true,
	},
	page: {
		...genProps('page', { inProject: true }),
		drizzleSchema: pageSchema,
		helpText:
			'Pages are the different screens that your customers see in your product',
		icon: 'file-text',
		inProject: true,
		relations: {
			// ...manyToMany('event', eventToFeature),
			// ...manyToMany('material', materialToFeature),
			// ...manyToMany('tag', tagToP),
			// ...hasOne('project'),
		},
		schema: z.object({
			...schemas,
			tagIds: idList,
		}),
		starable: true,
	},
	pain: {
		...genProps('pain', { inProject: true }),
		drizzleSchema: painSchema,
		helpText: 'Pains are the problems that your customers have',
		icon: 'stethoscope',
		inProject: true,
		relations: {
			// ...hasOne('project'),
		},
		schema: z.object({
			...schemas,
			tagIds: idList,
		}),
	},
	persona: {
		...genProps('persona', { inProject: true }),
		drizzleSchema: personaSchema,
		helpText: 'Personas are the types of users that use your product',
		icon: 'square-user-round',
		inProject: true,
		relations: {
			...manyToMany('userFlow', personaToUserFlow),
			// ...manyToMany('material', materialToPersona),
			// ...manyToMany('tag', tagToCustomer),
		},
		schema: z.object({
			...schemas,
			tagIds: idList,
		}),
		starable: true,
	},
	project: {
		...genProps('project', { inProject: false }),
		drizzleSchema: projectSchema,
		helpText: 'Projects are a collection of tasks for a customer',
		icon: 'square-kanban',
		inProject: false,
		relations: {
			// ...hasMany('task'),
			...manyToMany('tag', tagToProject),
		},
		schema: z.object({
			...schemas,
			// ...hasMany('feature'),
			tagIds: idList,
		}),
		starable: true,
	},
	role: {
		...genProps('role', { inProject: false, isAdmin: true }),
		drizzleSchema: roleSchema,
		helpText: 'User roles',
		icon: 'square-user-round',
		inProject: false,
		relations: {},
		schema: z.object({
			...omit(schemas, ['newTags']),
		}),
	},
	step: {
		...genProps('step', { inProject: true }),
		drizzleSchema: stepSchema,
		helpText:
			'User Flows are the steps that your customers take to use your product',
		icon: 'workflow',
		inProject: true,
		relations: {
			// ...manyToMany('event', eventToFeature),
			// ...manyToMany('material', materialToFeature),
			// ...manyToMany('tag', tagToFeature),
			...hasOne('userFlow'),
		},
		schema: z.object({
			...schemas,
			tagIds: idList,
			userFlow: z.string(),
		}),
	},
	tag: {
		...genProps('tag', { inProject: false }),
		drizzleSchema: tagSchema,
		helpText: 'Tags are used to categorize customers.',
		icon: 'newspaper',
		inProject: false,
		relations: {
			...manyToMany('feature', tagToFeature),
			...manyToMany('project', tagToProject),
		},
		schema: z.object({ ...schemas }),
	},
	userFlow: {
		...genProps('userFlow', { inProject: true }),
		drizzleSchema: userFlowSchema,
		helpText:
			'User Flows are the steps that your customers take to use your product',
		icon: 'workflow',
		inProject: true,
		relations: {
			...manyToMany('persona', personaToUserFlow),
			// ...manyToMany('material', materialToFeature),
			// ...manyToMany('tag', tagToFeature),
			// ...hasOne('project'),
		},
		schema: z.object({
			...schemas,
			tagIds: idList,
		}),
	},
} as const satisfies { [K in (typeof modelNames)[number]]: Model<K> }

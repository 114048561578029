import { standardFields, tenantSchema } from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, uniqueIndex } from 'drizzle-orm/pg-core'
import { omit } from 'es-toolkit'
import { projectSchema } from './projectSchema'

const tableName = 'marketing'

export const marketingSchema = pgTable(
	tableName,
	(t) => ({
		...omit(standardFields, ['ownerId']),
		heroDescription: t.text(),
		heroTitle: t.text(),
		projectId: t.varchar().references(() => projectSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
	}),
	(t) => [uniqueIndex().on(t.projectId, t.tenantId)],
)

export const marketingRelations = relations(marketingSchema, ({ one }) => ({
	projectId: one(projectSchema, {
		fields: [marketingSchema.projectId],
		references: [projectSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [marketingSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))

import { omit } from '#app/utils/lodash.ts'
import {
	standardFields,
	tenantSchema,
	userSchema,
} from '#db/schema/baseSchema.ts'
import { relations } from 'drizzle-orm'
import { pgTable, uniqueIndex, varchar } from 'drizzle-orm/pg-core'
import { projectSchema } from './projectSchema'

const brandLogoTableName = 'brand_logo'

export const brandLogoSchema = pgTable(
	brandLogoTableName,
	(t) => ({
		...omit(standardFields, ['name', 'description']),
		altText: t.varchar(),
		blob: t.text().notNull(),
		contentType: t.varchar().notNull(),
		projectId: t.varchar().references(() => projectSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
	}),
	(table) => [uniqueIndex().on(table.tenantId, table.projectId)],
)

export const brandLogoRelations = relations(brandLogoSchema, ({ one }) => ({
	ownerId: one(userSchema, {
		fields: [brandLogoSchema.ownerId],
		references: [userSchema.id],
	}),
	projectId: one(projectSchema, {
		fields: [brandLogoSchema.projectId],
		references: [projectSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [brandLogoSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))

const brandColorTableName = 'brand_color'

export const brandColorSchema = pgTable(
	brandColorTableName,
	{
		...omit(standardFields, ['name', 'description']),
		primaryColor: varchar('primary_color').notNull(),
		projectId: varchar('project_id').references(() => projectSchema.id, {
			onDelete: 'cascade',
			onUpdate: 'cascade',
		}),
	},
	(table) => [uniqueIndex().on(table.tenantId, table.projectId)],
)

export const brandColorRelations = relations(brandColorSchema, ({ one }) => ({
	ownerId: one(userSchema, {
		fields: [brandColorSchema.ownerId],
		references: [userSchema.id],
	}),
	projectId: one(projectSchema, {
		fields: [brandColorSchema.projectId],
		references: [projectSchema.id],
	}),
	tenantId: one(tenantSchema, {
		fields: [brandColorSchema.tenantId],
		references: [tenantSchema.id],
	}),
}))

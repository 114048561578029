import { omit } from '#app/utils/lodash.ts'
import { relations } from 'drizzle-orm'
import { pgTable, primaryKey, uniqueIndex, varchar } from 'drizzle-orm/pg-core'

import { standardFields, tenantSchema, userSchema } from './baseSchema.ts'

export const roleSchema = pgTable(
	'role',
	(t) => ({
		...omit(standardFields, ['ownerId']),
		internal: t.boolean(),
	}),
	(table) => [uniqueIndex().on(table.tenantId, table.name)],
)

export const roleRelations = relations(roleSchema, ({ many, one }) => ({
	tenant: one(tenantSchema, {
		fields: [roleSchema.id],
		references: [tenantSchema.id],
	}),
	users: many(userToRole),
}))

export const userToRole = pgTable(
	'user_to_role',
	{
		roleId: varchar('role_id')
			.notNull()
			.references(() => roleSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			})
			.notNull(),
		tenantId: varchar('tenant_id')
			.notNull()
			.references(() => tenantSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			})
			.notNull(),
		userId: varchar('user_id')
			.notNull()
			.references(() => userSchema.id, {
				onDelete: 'cascade',
				onUpdate: 'cascade',
			})
			.notNull(),
	},
	(t) => ({
		pk: primaryKey({ columns: [t.tenantId, t.userId, t.roleId] }),
	}),
)

export const userToRoleRelations = relations(userToRole, ({ one }) => ({
	role: one(roleSchema, {
		fields: [userToRole.roleId],
		references: [roleSchema.id],
	}),
	tenant: one(tenantSchema, {
		fields: [userToRole.userId],
		references: [tenantSchema.id],
	}),
	user: one(userSchema, {
		fields: [userToRole.userId],
		references: [userSchema.id],
	}),
}))
